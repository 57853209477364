<template>
  <div class="pre-exam">
    <MyBreadcrumb></MyBreadcrumb>
    <div class="pre-exam-cont" v-loading="loading">
      <div class="exam-tit">{{ trainDeatil.name }}考试信息</div>
      <div class="exam-cont">
        <div class="exam-cont-tit">考前须知:</div>
        <div class="exam-cont-nr" v-if="trainDeatil.attr && trainDeatil.attr.content">
          {{ trainDeatil.attr.content }}
        </div>
        <div v-else class="exam-cont-nr">
          <div>1. 时间安排：请同学们提前规划好答题时间，合理安排复习和休息时间。</div>
          <div>2. 复习重点：请同学们重点复习教材中的重点知识点和难点，同时也要注意平时作业和练习题的复习。</div>
          <div>3. 考试纪律：请同学们遵守纪律，不得抄袭、作弊等违规行为；</div>
          <div>4. 考试心态：请同学们保持良好的考试心态，不要过于紧张或放松。</div>
        </div>
      </div>
      <div class="exam-cont" style="margin-top: 20px;" v-if="!from">
        <div class="exam-cont-tit">请核对你的信息，无误后即可开始考试！（若存在疑问，请联系监考老师）:</div>
<!--        <div class="exam-cont-nr">-->
<!--          <span style="padding-right: 50px" v-if="$store.state?.user?.userInfo?.realname">真实姓名：{{$store.state.user.userInfo.realname}}</span>-->
<!--          <span v-if="$store.state?.user?.userInfo?.id_card_num">身份证号：{{$store.state?.user?.userInfo?.id_card_num}}</span>-->
<!--        </div>-->
        <div class="check-info">
          <div >
            <span>真实姓名：</span>
            <el-input v-model="userDetail.realname" placeholder="请输入真实姓名"></el-input>
          </div>
          <div>
            <span>准考证号：</span>
            <el-input v-model="userDetail.examine_card_number" maxlength="20" placeholder="请输入准考证号"></el-input>
          </div>
          <el-button type="primary" @click="changeUserInfo">确定</el-button>
        </div>
      </div>

      <div class="exam-cont monitor" v-if="trainDeatil.is_open_monitor==='1'">
        <div class="exam-cont-tit" style="text-align: center;">考试前请先开启音视频，若未开启则无法开始考试</div>
        <div class="monitor-content">
          <div class="video-wrap">
            <video autoplay playsinline ref="localVideo"></video>
            <div class="camear-tips" v-if="!camera">
              未检测到摄像头
              <el-button round size="small" @click="refreshWindow()">刷新检测</el-button>
            </div>
            <div class="camear-text">实时摄像头画面</div>
          </div>
          <div class="camera-mic">
            <!-- <div class="mic">
              <i class="el-icon-microphone"></i>
              麦克风：
              <el-progress v-if="mic" :percentage="volume" :show-text="false"></el-progress>
              <el-tag v-else type="danger" size="small">未检测到声音</el-tag>
            </div> -->
            <div class="video-wrap">
              <div class="volume">
                <div class="el-icon-microphone" style="font-size: 20px;"></div>
                <el-progress :percentage="volume" :stroke-width="10" :show-text="false"></el-progress>
              </div>
              <div class="camear-tips" v-if="!mic">
                未检测到音频
                <el-button round size="small" @click="refreshWindow()">刷新检测</el-button>
              </div>
              <div class="camear-text">音频检测</div>
            </div>
          </div>
        </div>
      </div>

      <div class="exam-info">
        <div class="exam-info-nr">
          <p>考试时间：{{ trainDeatil.timelong }}分钟</p><span>|</span>
          <p>通过条件：{{ trainDeatil.pass_score }}分（满分{{ trainDeatil.total_score }}）</p>
        </div>
      </div>
      <div class="start-exam-time" v-if="!this.isSelf">
        距离开考：<span>{{ sec >= 0 ? useTime : '已到答题时间' }}</span>
      </div>
      <div class="exam-btn" :class="sec > 0 ? 'gray' : ''" @click="startBtn"
        v-if="trainDeatil.status == 1 || trainDeatil.status == 2">开始考试</div>

    </div>
    <el-dialog :visible.sync="confirmExamDialog" width="310px" :close-on-click-modal="false"
      :close-on-press-escape="false" :show-close="false">
      <div slot="title"></div>

      <div class="confirm-submit-txt">确定开始考试吗？</div>
      <!--      <div class="confirm-submit-txt-sub">开始考试后将扣除1次考试次数</div>-->
      <div slot="footer" class="confirm-submit-txt-btn">
        <template>
          <div @click="cancelExam">先不考了</div>
          <div class="active" @click="startExam">开始考试</div>
        </template>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import util from '@/utils/tools.js';
import { MessageBox } from 'element-ui';
export default {
  name: "preExam.vue",
  data() {
    return {
      loading: false,
      trainId: '',
      trainDeatil: {
        is_open_monitor:"0"
      },
      useTime: '',
      timer: null,
      sec: '',
      typeStat: {},
      dataTypes: [],
      trainUser: {},
      confirmExamDialog: false,
      from: '',
      examineId: '',
      isSelf: false, //自测模考
      train: {},


      camera: false,
      mic: false,
      volume: 0,
      tracks:[],
      userDetail:{},
    }
  },
  created() {
    this.from = this.$route.query.from || '';
    this.isSelf = this.$route.query.isSelf || false;
    if (this.from == 'courseWeek') {  //课程的周测试
      this.examineId = this.$route.query.id || '';
      this.getCourseDetail();
    } else {
      this.trainId = this.$route.query.id;
      this.getDetail();
      this.getUserDetail();
    }

  },
  methods: {
    getUserDetail(){
      this.api.user.userDetail().then((res)=>{
        this.userDetail = res.user;
      })
    },
    getDetail() {
      let params = {
        id: this.trainId
      };
      this.loading = true;
      this.api.dataset.trainDataDetail(params).then((res) => {
        this.loading = false;
        this.trainDeatil = res.info;
        // 如果试卷开启了监控，那就检测是否有摄像头和麦克风
        if(this.trainDeatil.is_open_monitor==='1'){
          this.detectingMedia();
        }
        this.typeStat = res.typeStat;
        let start_time = +res.info.start_time;
        this.dataTypes = [];
        for (let name in res.dataTypes) {
          this.dataTypes.push({
            name: res.dataTypes[name],
            id: name
          })
        }

        // this.examDeatil.batch.name = util.decrypt(this.examDeatil.batch.name);

        this.setTime(start_time);

        clearInterval(this.timer)
        this.timer = setInterval(() => {
          this.setTime(start_time);
        }, 1000)



        this.trainDeatil.start_time = util.timeFormatter(
          new Date(+res.info.start_time * 1000),
          'yyyy-MM-dd hh:mm'
        );
        this.trainDeatil.end_time = util.timeFormatter(
          new Date(+res.info.end_time * 1000),
          'yyyy-MM-dd hh:mm'
        );



        // this.trainUser = res.trainUser;



      }).catch((err) => {
        this.loading = false;
      })
    },
    getCourseDetail() {
      let params = {
        id: this.examineId
      };
      this.loading = true;
      this.api.course.examineDetail(params).then((res) => {
        this.loading = false;
        this.trainDeatil = res.info;
        this.train = res.train;
        this.typeStat = res.typeStat;
        let start_time = +res.info.start_time;
        this.dataTypes = [];
        for (let name in res.dataTypes) {
          this.dataTypes.push({
            name: res.dataTypes[name],
            id: name
          })
        }

        // this.examDeatil.batch.name = util.decrypt(this.examDeatil.batch.name);

        this.setTime(start_time);

        clearInterval(this.timer)
        this.timer = setInterval(() => {
          this.setTime(start_time);
        }, 1000)



        this.trainDeatil.start_time = util.timeFormatter(
          new Date(+res.info.start_time * 1000),
          'yyyy-MM-dd hh:mm'
        );
        this.trainDeatil.end_time = util.timeFormatter(
          new Date(+res.info.end_time * 1000),
          'yyyy-MM-dd hh:mm'
        );



        // this.trainUser = res.trainUser;



      }).catch((err) => {
        this.loading = false;
      })
    },
    setTime(startTime) {
      let oDate = new Date().getTime();
      this.sec = Math.floor((startTime * 1000 - oDate) / 1000);
      if (this.sec <= 0) {
        clearInterval(this.timer)
      }
      this.useTime = util.secondsToMS(this.sec);
      // let useTime = util.timeFormate(this.sec);
      // this.useTime = (!!useTime.hours ? this.toDou(useTime.hours) + ':' : '') + (!!useTime.minutes ? this.toDou(useTime.minutes) + ':' : '00:') + this.toDou(useTime.seconds);
    },
    toDou(num) {
      return num < 10 ? '0' + num : num;
    },

    startBtn() {
      if (this.sec <= 0) {
        if(this.trainDeatil.is_open_monitor==='1'){
          if(!this.camera){
            MessageBox.alert("请打开摄像头","注意",{type:"warning"});
            return;
          }
          if(!this.mic){
            MessageBox.alert("请打开麦克风","注意",{type:"warning"});
            return;
          }
        }
        if (this.trainId || this.train.id) {
          let params = {
            action: 'trainUserInfo',
            train_id: this.trainId || this.train.id || this.trainDeatil.id
          };
          this.api.dataset.trainExecute(params).then((res) => {
            this.trainUser = res.train_user;
            if (!res.train_user.start_time || res.train_user.start_time == '0') {
              this.confirmExamDialog = true;
            } else {
              this.goExam();
            }
          })
        } else {
          //随机考试用的
          let params = {
            action: 'checkTrain',
            data_type: 13,
            data_rule: 12,
            generate_id: this.examineId,
            from_type: 6,
            from_id: this.examineId,
            type: 1,
          };
          this.api.dataset.trainExecute(params).then((res) => {
            let params = {
              action: 'trainUserInfo',
              train_id: res.train.id
            };
            this.api.dataset.trainExecute(params).then((res) => {
              this.trainUser = res.train_user;
              if (!res.train_user.start_time || res.train_user.start_time == '0') {
                this.confirmExamDialog = true;
              } else {
                this.goExam();
              }
            })
          })

        }
      }
      // this.confirmExamDialog = true;
      // this.$router.push({
      //   path:'/attestation/exam',
      //   query:{
      //     id:this.$route.query.id
      //   }
      // })
    },
    // startExam(){
    //   if(this.sec <= 0){
    //     this.$router.push({
    //       path:'/exam',
    //       query:{
    //         id:this.trainDeatil.id,
    //       }
    //     })
    //   }
    // },
    startExam() {
      let params = {
        action: 'trainUserStart',
        train_user_id: this.trainUser.id
      };

      this.api.dataset.trainExecute(params).then((res) => {
        this.confirmExamDialog = false;
        // this.$router.push({
        //   path:'/exam',
        //   query:{
        //     id:this.trainDeatil.id,
        //   }
        // })
        this.goExam();
      })

    },
    goExam() {
      if (this.from == 'courseWeek') {
        if (this.train.id) {
          this.$router.push({
            path: '/exam',
            query: {
              id: this.train.id,
            }
          })
        } else {
          this.$router.push({
            path: '/exam',
            query: {
              examineId: this.trainDeatil.id,
            }
          })
        }
      } else {
        this.$router.push({
          path: '/exam',
          query: {
            id: this.trainDeatil.id,
            isSelf: this.isSelf
          }
        })
      }

    },
    cancelExam() {
      this.confirmExamDialog = false;
    },
    detectingMedia() {
      // 检测摄像头是否开启
      navigator.mediaDevices.getUserMedia({
        video: true
      }).then(stream=>{
        this.$refs.localVideo.srcObject = stream;
        this.tracks=[...this.tracks,...stream.getTracks()];
        this.camera=true;
      });
      // 检测麦克风是否开启
      navigator.mediaDevices.getUserMedia({
        audio: true
      }).then(stream => {
        // 音频上下文
        const audioContext = new AudioContext();
        // 创建分析器
        const analyser = audioContext.createAnalyser();
        // 创建媒体流源
        const microphone = audioContext.createMediaStreamSource(stream);
        // 麦克风连接分析器？
        microphone.connect(analyser);
        // 分析器连接音频上下文的目的地？
        analyser.connect(audioContext.destination);
        // 采样范围？
        analyser.fftSize = 128;
        // 频率纸盒计数
        const bufferLength = analyser.frequencyBinCount;
        // ？？？
        const dataArray = new Uint8Array(bufferLength);

        const updateVolume = () => {
          analyser.getByteFrequencyData(dataArray);
          let volume = dataArray.reduce((acc, val) => acc + val, 0) / bufferLength;
          if (volume > 100) {
            volume = 100;
          }
          this.volume = volume;
          // 浏览器每绘制一帧，调一次函数
          requestAnimationFrame(updateVolume);
        }
        updateVolume();
        this.tracks=[...this.tracks,...stream.getTracks()];
        this.mic=true;
      });
    },
    // 刷新检测
    refreshWindow(){
      location.reload();
    },
    changeUserInfo(){
      let params = {
        user_id:this.userDetail.id,
        realname:this.userDetail.realname,
        examine_card_number:this.userDetail.examine_card_number

      };
      this.api.user.userUpdate(params).then((res)=>{
        this.$Message.success('修改成功');
        this.getUserDetail();
      });
    }
  },
  beforeDestroy(){
    if(this.trainDeatil.is_open_monitor==='1'){
      this.tracks.forEach(track => track?.stop());
    }
  }
}
</script>

<style scoped lang="scss">
.pre-exam {
  padding: 20px;
  height: 100%;
  font-size: 14px;

  .pre-exam-cont {
    //padding-top: 10px;
    background-color: #FFFFFF;
    font-size: 14px;
    height: calc(100% - 40px);
    overflow-y: auto;


    .exam-tit {
      padding: 60px 0 30px 0;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
    }

    .exam-cont {
      margin: 0 auto;
      padding: 30px 0;
      width: 1000px;
      background: #FAFBFC;
      border: 1px solid #EEEEEE;

      .exam-cont-tit {
        margin: 0 40px 20px 40px;
        font-size: 16px;
      }

      .monitor-content {
        display: flex;
        justify-content: center;
        align-items: center;

        .video-wrap {
          position: relative;
          width: 230px;
          height: 130px;
          background-color: #F4F4F4;

          >video {
            background-color: black;
            width: 100%;
            height: 100%;
            border-radius: 2px;
          }

          .volume{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 32px;
            .el-progress{
              width: 80%;
            }
          }

          .camear-text{
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #333333;
            opacity: 0.9;
            color: white;
            z-index: 20;
          }

          .camear-tips {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            padding-bottom: 20px;
            justify-content: center;
            align-items: center;
            background-color: #F4F4F4;
            z-index: 10;
            .el-button{
              margin-top: 10px;
            }
          }
        }

        .camera-mic {
          display: flex;
          align-items: center;
          padding: 20px 10px;

          .mic {
            display: flex;
            align-items: center;
            font-size: 20px;

            .el-progress {
              width: 200px;
            }
          }
        }

      }

      .exam-cont-nr {
        margin-left: 40px;
        line-height: 24px;
        white-space: pre-wrap;
      }
      .check-info{
        margin: 0 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        >div{
          margin-right: 40px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          >span{
            display: inline-block;
            width: 100px;
          }
        }
      }

    }

    .monitor {
      margin-top: 20px;
    }

    .start-exam-time {
      margin-top: 40px;
      text-align: center;
      font-size: 16px;

      >span {
        font-weight: bold;
        color: #5782FF;
        font-size: 18px;
      }
    }

    .exam-info {
      margin-top: 64px;
      display: flex;
      justify-content: center;
      align-items: center;

      .exam-info-nr {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        color: #4A79FF;

        >span {
          padding: 0 10px;
        }
      }


      .exam-info-btn {
        margin-left: 30px;
        padding: 0 5px;
        height: 24px;
        border-radius: 4px;
        font-size: 12px;
        color: #666666;
        background-color: #E0E0E0;
      }
    }

    .exam-btn {
      margin: 20px auto 18px auto;
      width: 240px;
      height: 48px;
      border-radius: 24px;
      font-size: 16px;
      color: #FFFFFF;
      background: #4A79FF;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .gray {
      background: #666666;
    }

    .exam-record {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      cursor: pointer;

      >img {
        margin-left: 5px;
      }
    }
  }

  .confirm-submit-txt {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }

  .confirm-submit-txt-sub {
    margin-top: 10px;
    text-align: center;
  }

  .confirm-submit-txt-btn,
  .result-modal-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    >div {
      margin-right: 14px;
      width: 100%;
      height: 38px;
      border-radius: 19px;
      font-size: 14px;
      background-color: #E1E9FF;
      color: #4A79FF;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }


    .active {
      margin-right: 0;
      width: 100%;
      height: 38px;
      border-radius: 19px;
      font-size: 14px;
      background: linear-gradient(222deg, #3A6CFF, #004FEB);
      color: #FFFFFF;
    }
  }
}</style>
<style>/*.chapters .el-tree-node__content{*/
/*  height: 44px !important;*/
/*}*/</style>

